const __o365_sfc__ = {};
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", null, " Hei "))
}
    __o365_sfc__.name = 'Buttons';
    __o365_sfc__.__file = 'Buttons';
    __o365_sfc__.render = render;
    
    export default __o365_sfc__;